export const environment = {
  production: true,
  envName: 'prod',
  API_URL: 'https://api-now.engineer.ai',
  STUDIO_API_URL: 'https://api-builder.engineer.ai',
  STUDIO_URL: 'https://studio.builder.ai',
  BUILDER_PAYMENT_URL: 'https://studio.builder.ai/instant_prototype?build_card_id={buildCardId}',
  BUILDER_FEATURES_URL: 'https://studio.builder.ai/#/features',
  USER_COOKIE: 'user_prod',
  GUIDLINE_TOOTIP_COOKIE: 'prod_guidline',
  enableSentry: false,
  tracker_user: 'tracker_prod',
  tracker_user_beta: 'tracker_prod_beta',
  API_TRACKER_URL: 'https://api-tracker-uiw.builder.ai',
  API_NOW_URL: 'https://api-tracker-uiw.builder.ai',
  TRACKER_STAGING_URL: 'https://api.tracker.engineer.ai',
  API_360: 'https://api-pmdashboard.engineer.ai/',
  API_360_TOKEN:'DISMOytihdIKoypUaAmn',
  redirectionTracker: 'https://tracker.builder.ai',
  bucket_document: 'https://builder-now-production.s3.amazonaws.com',
  base_Sketch:'https://cmt.builder.ai',
  base_Sketch_bucket:'https://prod-cmt-assets.s3.amazonaws.com/',
  API_360_KEY_NOTES:'XpYHPfnPuKTUCnXRgRuL',
  white_board_base_url: "https://www.whiteboard.team",
  whiteBoardClientId: "808aab85aeb95397b9251e32e19a9881",
  whiteBoardClientSecret: "9a1d2fca5dec552a6573ce6dc29511e8",
  nowUrl:'https://now.builder.ai',
  HIVE_URL: 'https://developer.builder.ai/login',
  X_FIGMA_TOKEN:'figd_O8OWQzWMqDHMwmcgkAr18Hcwhj0h1IHgpmQ-ar6i',
  FIGMA_URL: 'https://www.figma.com',
  apeiros:'https://whiteboard.builder.ai',
  cmtFeedBackLoop :'https://cmt.builder.ai/api/v2/',
  cmtToken : '48713b2-951b-403a-9f8c-3f75a1304daf',
  nowToken : 'dYgfiqEze9d95WpMnquf',
  trackerSocekt: 'https://api.tracker.engineer.ai/api/v1/sockets/auth',
  socketUrl: 'wss://centrifugo.tracker.engineer.ai/connection/websocket',
  cmtUserName:'uiw_now_production',
  API_360_BCHT:'QHDkZwJYZBCUeEyZDdzP',
  bchat_env :'bchat_production',
  authenticationToken:'YmNoYXRfcHJvZHVjdGlvbjpKMzJxT0pab3JFbXM1QENoYXQ',
  apiEndpointBchat:'chat.builder.ai'
 };
